



























































































// import { Office } from 'client-website-ts-library/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import NavigationLink from './NavigationLink.vue';

@Component({
  components: {
    NavigationLink,
  },
})
export default class Header extends Vue {
  @Prop()
  private readonly mobileNavActive!: boolean;

  @Prop()
  private readonly isStuck!: boolean;

  //   @Prop()
  //   private readonly offices!: Office[];
}
